<template>
  <v-navigation-drawer
    top
    color="white"
    fixed
    height="auto"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
    >
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :exact="item.text === 'Home'"
        color="primary"
        @click="$emit('navigate', item)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn
          block
          rounded
          small
          class="mr-4 pl-3"
          color="primary"
          href="https://app.lavvira.net"
        >
          log in
          <v-icon
            small
            class="ml-1"
          >
            mdi-login
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      // items are comming from AppBar.vue
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
